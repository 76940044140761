require('./bootstrap');

$(document).ready(function () {
    mixpanel.track('Landing page loaded');
    runVantaHaloDarkMode();

    $("#homeButton").click(function () {
        window.location.href = "/"
    });

    $("#loginButton").click(function () {
        window.location.href = "/login"
    });

    $("#bodyTag").resize(function () {
        setVantaLoginForm();
    });

    setVantaLoginForm();
});

// Vanta
function runVantaHalo(baseColor, backgroundColor) {
    if ($("#vanta-login")[0] !== undefined) {
        VANTA.HALO({
            el: "#vanta-login",
            mouseControls: false,
            touchControls: false,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            baseColor: baseColor,
            backgroundColor: backgroundColor,
            amplitudeFactor: 0.00,
            size: 1.90
        });
    }
}

function setVantaLoginForm() {
    let vanta = $("#vanta-login")[0];
    if (vanta) {
        let form = $("#login-form");
        form.offset({ top: vanta.offsetTop + 230 });
    }
}

// Light or Dark mode

function manageDarkMode() {
    let mode = getFromLocalStorage("mode");
    if (!mode) {
        setToLocalStorage("mode", "dark")
    }
    runVantaHaloLightMode();

    if ("dark" === mode) {
        enableDarkMode();
        runVantaHaloDarkMode();
    }
}

$("#toggleLightDarkMode").click(function () {
    const isEnabled = DarkReader.isEnabled();

    if (isEnabled) {
        disableDarkMode();
        runVantaHaloLightMode();
    } else {
        enableDarkMode();
        runVantaHaloDarkMode();
    }
});

function runVantaHaloDarkMode() {
    runVantaHalo(0x4519bd, 0x4519bd);
}

function runVantaHaloLightMode() {
    runVantaHalo(0x4519bd, 0x4519bd);
}

function disableDarkMode() {
    DarkReader.disable();
    setToLocalStorage("mode", "light");
}

function enableDarkMode() {
    DarkReader.enable({
        brightness: 75,
        contrast: 100,
        sepia: 0
    });
    setToLocalStorage("mode", "dark");
}

function getFromLocalStorage(key) {
    return window.localStorage.getItem(key);
}

function setToLocalStorage(key, value) {
    window.localStorage.setItem(key, value);
}

// End Light or Dark mode

animateTitle();
let titleAnimated = false;
let footerAnimated = false;
let servicesAnimated = false;
let services2Animated = false;
let services3Animated = false;


$(window).scroll(function () {
    let footerPos = parseInt($('#footer').offset().top);
    let scroll = $(window).scrollTop();

    let p = (scroll * 100) / footerPos;

    if (0 === scroll) {
        titleAnimated = false;
        footerAnimated = false;
        servicesAnimated = false;
        services2Animated = false;
        services3Animated = false;
        footerAnimated = false;
        bulletpointLexiconAnimated = false;
        bulletpointEncryptionAnimated = false;
        bulletpointStreamsAnimated = false;
    }

    if (p >= 0 && !titleAnimated) {
        animateTitle();
        titleAnimated = true;
    }

    if (p >= 30 && !servicesAnimated) {
        animateServices();
        servicesAnimated = true;
    }

    if (p >= 35 && !services2Animated) {
        animateServices2();
        services2Animated = true;
    }

    if (p >= 40 && !services3Animated) {
        animateServices3();
        services3Animated = true;
    }

    if (p >= 80 && !footerAnimated) {
        animateFooter();
        footerAnimated = true;
    }
});

function write(text, elementId, delay) {

    let app = document.getElementById(elementId);

    let typewriter = new Typewriter(app, {
        delay: delay,
        cursor: ""
    });

    typewriter
        .typeString(text)
        .start();
}

function addCustomClass(className, elementId) {
    let element = $('#' + elementId);
    if (element.hasClass(className)) {
        element.removeClass(className);
    }
    element.addClass(className);
}

function animateTitle() {
    writerStandard("welcome-title", 15);
}

function animateServices() {
    writerStandard("services-title", 15);
}

function animateServices2() {
    writerStandard("services2-title", 15);
}

function animateServices3() {
    writerStandard("services3-title", 15);
}

function animateBulletpointLexicon() {
    writerStandard("bulletpoint-lexicon-desc", 15);
}

function animateBulletpointEncryption() {
    writerStandard("bulletpoint-encryption-desc", 15);
}

function animateBulletpointStreams() {
    writerStandard("bulletpoint-stream-desc", 15);
}
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function animateFooter() {
    setTimeout(() => {  writerStandard("footer-see-more", 15) }, 2000);
}

function writerStandard(elementId, delay) {

    if (window.alreadyWritten == undefined)
        window.alreadyWritten = new Array();

    let element = document.getElementById(elementId);

    if (element && !window.alreadyWritten.includes(elementId)) {
        write(element.innerText, elementId, delay);
        window.alreadyWritten.push(elementId);
    }
}
